import * as Msal from 'msal'
import { msalConfig, loginRequest, tokenRequest } from './authConfig'
import { b2cPolicies } from './policies'
import { logMessage } from './ui'
// import { callApiWithAccessToken } from './api'
// import { apiConfig } from './apiConfig'
import store from '@/store'

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new Msal.UserAgentApplication(msalConfig)

export async function signIn (user) {
  return new Promise(function (resolve, reject) {
    console.log('=== processing Msal ===')
    console.log(msalConfig.auth.redirectUri)
    myMSALObj.loginPopup(loginRequest)
      .then(async loginResponse => {
        console.log('id_token acquired at: ' + new Date().toString())
        console.log('myMSALObj.getAccount() :', myMSALObj.getAccount())
        // if (myMSALObj.getAccount()) {
        //   updateUI()
        // }
        const account = myMSALObj.getAccount().accountIdentifier
        await passTokenToApi()
        resolve(account)
      }).catch(error => {
        console.log(`An error occured during MSAL login process. error: ${error}`)
        if (error.errorMessage) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            myMSALObj.loginPopup(b2cPolicies.authorities.forgotPassword)
              .then(loginResponse => {
                console.log(loginResponse)
                window.alert('Password has been reset successfully. \nPlease sign-in with your new password.')
              })
          }
        }
      })
  })
}

// Sign-out the user
export function cloudLogout () {
  myMSALObj.logout()
}

function getTokenPopup (request) {
  return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
      console.log('Silent token acquisition fails. Acquiring token using popup')
      console.log(error)
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenPopup(request)
        .then(tokenResponse => {
          console.log('access_token acquired at: ' + new Date().toString())
          return tokenResponse
        }).catch(error => {
          console.log(error)
        })
    })
}

// Acquires and access token and then passes it to the API call
export function passTokenToApi () {
  return getTokenPopup(tokenRequest)
    .then(tokenResponse => {
      console.log('access_token acquired at: ' + new Date().toString())
      try {
        logMessage('Request made to Web API:')
        store.commit('auth/UPDATE_TOKEN', tokenResponse)
        console.log('tokenResponse :', tokenResponse)
        return tokenResponse
        // callApiWithAccessToken2(apiConfig.webApi, tokenResponse.accessToken)
      } catch (err) {
        console.log(err)
      }
    })
}

// helper function to access the resource with the token
export function callApiWithAccessToken2 (endpoint, token) {
  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers
  }

  fetch(endpoint, options)
    .then(response => response.json())
    .then(response => {
      logMessage('Web API returned:\n' + JSON.stringify(response))
    }).catch(error => {
      logMessage('Error calling the Web api:\n' + error)
    })
}

export function editProfile () {
  myMSALObj.loginPopup(b2cPolicies.authorities.editProfile)
    .then(tokenResponse => {
      console.log('access_token acquired at: ' + new Date().toString())
      console.log(tokenResponse)
    })
}
